<template>
  <div class="vue-time--picker">
    <client-only>
      <vue-timepicker
        v-model="valueVModel"
        :format="format"
        :minute-interval="step"
        ref="timePickerRef"
      />
    </client-only>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker';

export default {
  name: 'date-picker',
  props: {
    value: {},
    format: {
      type: String,
    },
    step: {
      type: String,
      default: '1',
    },
  },
  components: { VueTimepicker },
  mounted() {
    this.openTimePicker();
  },
  computed: {
    valueVModel: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
  },
  methods: {
    openTimePicker() {
      setTimeout(() => {
        const timePicker = this.$refs.timePickerRef;
        if (timePicker) {
          const targetInputBox = timePicker.$el.querySelectorAll('input.display-time')[0];
          if (targetInputBox) {
            targetInputBox.focus();
          }
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss">
@import '~vue2-timepicker/dist/VueTimepicker.css';
</style>
